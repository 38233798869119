exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-request-demo-tsx": () => import("./../../../src/pages/request-demo.tsx" /* webpackChunkName: "component---src-pages-request-demo-tsx" */),
  "component---src-pages-store-reset-password-[token]-tsx": () => import("./../../../src/pages/store/reset-password/[token].tsx" /* webpackChunkName: "component---src-pages-store-reset-password-[token]-tsx" */),
  "component---src-pages-store-tsx": () => import("./../../../src/pages/store.tsx" /* webpackChunkName: "component---src-pages-store-tsx" */),
  "component---src-pages-store-welcome-tsx": () => import("./../../../src/pages/store-welcome.tsx" /* webpackChunkName: "component---src-pages-store-welcome-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/BlogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-store-item-page-template-store-item-page-template-tsx": () => import("./../../../src/templates/storeItemPageTemplate/storeItemPageTemplate.tsx" /* webpackChunkName: "component---src-templates-store-item-page-template-store-item-page-template-tsx" */),
  "component---src-templates-store-login-template-tsx": () => import("./../../../src/templates/storeLoginTemplate.tsx" /* webpackChunkName: "component---src-templates-store-login-template-tsx" */),
  "component---src-templates-store-register-template-tsx": () => import("./../../../src/templates/storeRegisterTemplate.tsx" /* webpackChunkName: "component---src-templates-store-register-template-tsx" */),
  "component---src-templates-store-reset-password-template-tsx": () => import("./../../../src/templates/storeResetPasswordTemplate.tsx" /* webpackChunkName: "component---src-templates-store-reset-password-template-tsx" */),
  "component---src-templates-wp-page-wp-page-tsx": () => import("./../../../src/templates/wpPage/wpPage.tsx" /* webpackChunkName: "component---src-templates-wp-page-wp-page-tsx" */)
}

