import "./src/styles/global.css";
import "react-multi-carousel/lib/styles.css";
import "babel-polyfill";

export const onClientEntry = () => {
  // Load Google Tag Manager script on client entry
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-K93Z4C3B");
};

// Load Smartsupp Live Chat script when the client renders
export const onInitialClientRender = () => {
  window._smartsupp = window._smartsupp || {};
  window._smartsupp.key = "baf12bb721da668c3e9f8f0f3ea11055f3022216";
  if (!window.smartsupp) {
    (function (d) {
      var s,
        c,
        o = (window.smartsupp = function () {
          o._.push(arguments);
        });
      o._ = [];
      s = d.getElementsByTagName("script")[0];
      c = d.createElement("script");
      c.type = "text/javascript";
      c.charset = "utf-8";
      c.async = true;
      c.src = "https://www.smartsuppchat.com/loader.js?";
      s.parentNode.insertBefore(c, s);
    })(document);
  }
};
